import React from 'react';
import { Link } from "react-router-dom";

const Footer = () => {

    return (
        <>
            <footer className="main_footer" role="contentinfo">
                <div className="container quote_hide_footer">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="footer_menu_row">
                                <div className="footer_menu_item">
                                    <ul>
                                        <li><Link to={"/"}>Car Insurance</Link></li>
                                        <li><Link to={"/"}>Health Insurance</Link></li>
                                        <li><Link to={"/"}>Term Insurance</Link></li>
                                        <li><Link to={"/"}>Fire Insurance</Link></li>
                                        <li><Link to={"/"}>Liability Insurance</Link></li>
                                        <li><Link to={"/"}>Marine Insurance</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer_full_black">
                    <div className="container quote_hide_footer">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="get_in_touch">
                                    <div className="get_in_touch_title">Get In Touch</div>
                                    <div className="get_in_touch_row">
                                        <div className="address_row">
                                            <span><i className="fa-solid fa-location-dot"></i></span>
                                            <p>
                                                <b>Head Office</b>
                                                <small>C/O BAIJNATH JAISWAL MAHADEVA BUZURG, ANANDNAGAR, ANANDNAGAR MAHARAJGANJ Maharajganj UP 273155 IN</small>
                                            </p>
                                        </div>
                                        <div className="contact_social">
                                            <div className="contact_details">
                                                <Link to={"tel:+91 9519166608"}><i className="fa-solid fa-phone"></i>+91 9519166608</Link>
                                                <Link to={"tel:+91 8369921864"}><i className="fa-solid fa-phone"></i>+91 8369921864</Link>
                                                <Link to={"mailto:AMIT3188@GMAIL.COM"}><i className="fa-solid fa-envelope"></i>AMIT3188@GMAIL.COM</Link>
                                            </div>
                                            <div className="social_media">
                                                <h3>Follow Us</h3>
                                                <span>
                                                    <Link to={"https://www.facebook.com/"} title="facebook" rel="noopener noreferrer nofollow" target="_blank"><i className="fa-brands fa-facebook"></i></Link>
                                                    <Link to={"https://www.instagram.com/"} title="instagram" rel="noopener noreferrer nofollow" target="_blank"><i className="fa-brands fa-instagram"></i></Link>
                                                    <Link to={"https://www.linkedin.com/"} title="linkedin" rel="noopener noreferrer nofollow" target="_blank"><i className="fa-brands fa-linkedin-in"></i></Link>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div >
                        </div >
                    </div >
                    <div className="footer_menu_row2">
                        <ul>
                            <li><Link to={"/"}>About Us</Link></li>
                            <li><Link to={"/"}>Products</Link></li>
                            <li><Link to={"/"}>Privacy Policy</Link></li>
                            <li><Link to={"/"}>Grievance Redress Policy</Link></li>
                            <li><Link to={"/"}>Terms & Conditions</Link></li>
                            <li><Link to={"/"}>License</Link></li>
                            <li><Link to={"/"}>ISNP</Link></li>
                            <li><Link to={"/"}>Contact Us</Link></li>
                        </ul>
                    </div>
                    <div className="copyright_block">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <p>AIBROM INSURANCE BROKING PRIVATE LIMITED | Reg. Office - C/O Baijnath Jaiswal Mahadeva Buzurg, Anand nagar, Maharajganj UP 273155 IN | IRDAI Direct Insurance Broker (Life & General) | License No.- 922 | Valid From - 07/12/2023 to Valid Till - 06/12/2026 Code No. - IRDAI/DB1040/2023 | CIN No - U67200UP2022PTC170642 | GST No - 09AAXCA6331L1Z0 | T&C Apply* Copyright © AIBROM INSURANCE BROKING PRIVATE LIMITED. All Rights Reserved</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </footer >
        </>
    );
};

export default Footer;
