import React, { lazy } from 'react';
import DefaultLayout from "../common-layouts/DefaultLayout";
// import QuoteLayout from "../common-layouts/QuoteLayout";


// Lazy load components
const Home = lazy(() => import("../components/home/Home"));
// const About = lazy(() => import("../components/about/About"));
// const Grievance = lazy(() => import("../components/policies/Grievance"));
// const License = lazy(() => import("../components/policies/License"));
// const Privacypolicy = lazy(() => import("../components/policies/Privacypolicy"));
// const ContactUs = lazy(() => import("../components/otherpage/ContactUs"));
// const HealthPrequotes = lazy(() => import("../components/health/HealthPrequotes"));
// const HealthQuotes = lazy(() => import("../components/health/HealthQuotes"));
const NotFound = lazy(() => import("../components/NotFound"));
// const Prequotes = lazy(() => import("../components/motor/Prequotes"));
// const Quotes = lazy(() => import("../components/motor/Quotes"));
// const Proposal = lazy(() => import("../components/motor/Proposal"));
// const Review = lazy(() => import("../components/motor/Review"));
// const PaymentSuccess = lazy(() => import("../components/payments_pages/PaymentSuccess"));
// const PaymentFailed = lazy(() => import("../components/payments_pages/PaymentFailed"));
// const PaymentSuccessHealth = lazy(() => import("../components/payments_pages/PaymentSuccessHealth"));
// const PaymentFailedHealth = lazy(() => import("../components/payments_pages/PaymentFailedHealth"));
// const HealthProposal = lazy(() => import("../components/health/HealthProposal"));
// const HealthReviews = lazy(() => import("../components/health/HealthReviews"));
// const Reference = lazy(() => import("../components/Reference"));

export const allRoute = [
  { path: "/", layout: DefaultLayout, component: Home },
  // { path: "/about-us", layout: DefaultLayout, component: About, exact: true },
  // { path: "/prequotes/two-wheeler-insurance", layout: DefaultLayout, component: Prequotes, exact: true, type: 1 },
  // { path: "/prequotes/car-insurance-online", layout: DefaultLayout, component: Prequotes, exact: true, type: 2 },
  // { path: "/prequotes/pcv-insurance/:vtype?", layout: DefaultLayout, component: Prequotes, exact: true, type: 3 },
  // { path: "/prequotes/goods-carring-vehicle-insurance/:vtype?/:ctype?", layout: DefaultLayout, component: Prequotes, exact: true, type: 4 },
  // { path: "/prequotes/miscellaneous-insurance/:ctype?", layout: DefaultLayout, component: Prequotes, exact: true, type: 5 },
  // { path: "/quotes/:id", layout: QuoteLayout, component: Quotes, exact: true },
  // { path: "/proposal/:id", layout: QuoteLayout, component: Proposal, exact: true },
  // { path: "/review/:id", layout: DefaultLayout, component: Review, exact: true },
  // { path: "/prequotes/health-insurance-online", layout: DefaultLayout, component: HealthPrequotes, exact: true },
  // { path: "/quote-health/:id", layout: QuoteLayout, component: HealthQuotes, exact: true },
  // { path: "/health/proposal/:id", layout: QuoteLayout, component: HealthProposal, exact : true },
  // { path: "/health/review/:id", layout: QuoteLayout, component: HealthReviews, exact : true },
  // { path: "/healths/payment-success/:id", layout: DefaultLayout, component: PaymentSuccessHealth, exact: true },
  // { path: "/healths/payment-failed/:id", layout: DefaultLayout, component: PaymentFailedHealth, exact: true },
  // { path: "/payment-success/:id", layout: DefaultLayout, component: PaymentSuccess, exact: true },
  // { path: "/payment-failed/:id", layout: DefaultLayout, component: PaymentFailed, exact: true },
  // { path: "/privacy-policy", layout: DefaultLayout, component: Privacypolicy, exact: true },
  // { path: "/grievance-redress-policy", layout: DefaultLayout, component: Grievance, exact: true },
  // { path: "/license", layout: DefaultLayout, component: License, exact: true },
  // { path: "/contact-us", layout: DefaultLayout, component: ContactUs, exact: true },
  // { path: "/reference", layout: QuoteLayout, component: Reference, exact: true },

  { path: "*", layout: DefaultLayout, component: NotFound }
];